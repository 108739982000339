import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';


import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTableRunciccfg from './MiDataTableRunciccfg';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import Button from '../../componentes/ui/Button';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';
import { Alert } from '../../componentes/ui/Alert';
import { alertService } from '../../services/alertservice';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';
import Informes from '../../componentes/formulario/informes/Informes';
import Modal from 'react-bootstrap/Modal';



import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';
import SpinnerFloat from '../../componentes/ui/SpinnerFloat';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { getApiUrl, axiosPost, dateFormatByLocale } from '../../helpers/helperApp';
import { async } from 'rxjs';


require('datatables.net-dt');


function RunCicCfg(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectMultipleFormPrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ idCiclo, saveIdCiclo ] = useState("");
    const [ loadingCiclo, saveLoadingCiclo ] = useState(true);
    const [ ciclo, saveCiclo ] = useState([]);
    const [ showModal, saveShowModal ] = useState(false);
    const [ idNuevoCiclo, saveIdNuevoCiclo ] = useState("");
    const [ loadingNuevoCiclo, saveLoadingNuevoCiclo ] = useState(false);
    const [ numSteps, saveNumSteps ] = useState([]);
    const [ titleTabs, saveTitleTabs ] = useState([]);
    const [ forceBtnNext, saveForceBtnNext ] = useState(0);
    const [ stepBtnNext, saveStepBtnNext ] = useState();
    const [ showModalNextStep, saveShowModalNextStep ] = useState(false);
    const [ loadingModalNextStep, saveLoadingModalNextStep ] = useState(false);
    const [ infoModalNextStep, saveInfoModalNextStep ] = useState({opt: 0, divi: 0, apl: 0});
    const [ tablaModalNextStep, saveTablaModalNextStep ] = useState();
    const [ txtModalDocs, saveTxtModalDocs ] = useState();

    
    // const [ datosTable, saveDatosTable ] = useState([]);
    // const [ regTabla, saveRegTabla ] = useState([]);
    // const [ datosTableRestoSteps, saveDatosTableRestoSteps ] = useState([]);

    const [ registrosSeleccionados, saveRegistrosSeleccionados ] = useState([]); 
    const [ datosTablas, saveDatosTablas ] = useState([]);
    const [ cantidadEdi, saveCantidadEdi ] = useState([]); 
    

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{         
                    document.title =confPantalla.config.trad.maintenance.title+" - "+confPantalla.runciccfg.title;           
                    if( confPantalla.runciccfg.panminlvl == null || cookies.get('nivel') <= confPantalla.runciccfg.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);
                            saveLoadingCiclo(false)                                          
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idciccfg": cookies.get('id'), "tabla": "undefined" })
            }
            document.querySelector("body").classList.remove("sidebar-open");
            // Token cancelación axios al desmontar componentes
            source.cancel();    
        } 
        
    },[confPantalla])
    
    const handleSelectCiclo = async (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            await getCicloEnCurso(selectedItem.value);
            await getDatosTable(selectedItem.value);
            saveIdCiclo(selectedItem.value);
        }else{
            saveIdCiclo("")
            saveCiclo([])
            saveRegistrosSeleccionados([])
            saveDatosTablas([])
        }
    }

    const handleSelectNuevoCiclo = (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            saveIdNuevoCiclo(selectedItem.value);
        }else{
            saveIdNuevoCiclo("")
        }
    }

    const showModalFn = () => {
        saveShowModal(true);
        saveIdNuevoCiclo("");
    }
    
    const getCicloEnCurso = async (idCiclo) => {    

        saveLoadingCiclo(true);
        saveCiclo([]);
        
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("idciclo", idCiclo);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.runciccfg.padre.getciclo, data, source.token);

        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            saveNumSteps(getLengthCiclo(respuesta.data.ciclo[0].numsteps));
            saveCiclo(respuesta.data.ciclo[0]);
            saveTitleTabs(respuesta.data.titletabs);            
        }
        saveLoadingCiclo(false);

    }

    const getLengthCiclo = (steps) => {
        let data = [];
        for(let i=0; i<steps; i++){
            data.push(i);
        }
        return data;
    }

    const getDatosTable = async (idCiclo) => {    

        saveLoadingCiclo(true);
        saveDatosTablas([]);
        
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("idciclo", idCiclo);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.runciccfg.padre.getdatostable, data, source.token);

        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            saveDatosTablas(datosTablas => [...datosTablas, respuesta.data])
        }
        saveLoadingCiclo(false);

    }

    const nuevoCiclo = async () => {    

        saveLoadingNuevoCiclo(true);
        
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("idciclo", idCiclo);
        data.append("idnuevociclo", idNuevoCiclo);
        data.append("step", 1);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.runciccfg.padre.createInitCiclo.getdata, data, source.token);
        saveShowModal(false);

        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            alertService.success(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.runciccfg.padre.createInitCiclo.success.title}`, {autoClose: true});
            getDatosTable(idCiclo);
        }

        saveLoadingNuevoCiclo(false);

    }

    const getHeaderModalNextStep = () => {        
        const info = $(".runciccfgTab_"+stepBtnNext).DataTable().rows( { selected: true } ).data()[0];
        const h5 = <div className="col-md-10 offset-1">
                        <div className="alert alert-secondary alertHelp text-center" role="alert" style={{margin: "0px"}}>
                            <h5 style={{marginBottom : "0px"}}>
                                {confPantalla.runciccfg.padre.helpmodal.pasoactual.title} {stepBtnNext}/{numSteps.length}:  
                                <span style={{fontWeight: "700"}}> {info.equitab != "" && info.equitab ? info.equitab+" - " : "" }{info.descripcion}</span>
                            </h5>
                        </div>
                    </div>;
        return h5;
    }

    const getTxtHelp = (idRow, id, datos, selectedRows, getSelected=false) =>{         
        let texto = "";
        if(getSelected){
            selectedRows = $('#tableModalDocs').DataTable().rows( { selected: true } ).data();
            let help = null;
            selectedRows.length == 0 
            ?
                help =  <div className="alert alert-success mt-1" role="alert" style={{width : "fit-content"}}>
                            <h5 style={{marginBottom : "0px"}}>{confPantalla.runciccfg.padre.helpmodal.crear.title}</h5>
                        </div>
            :
                help = <div className="alert alert-primary mt-1" role="alert" style={{width : "fit-content"}}>
                            <h5 style={{marginBottom : "0px"}}>{confPantalla.runciccfg.padre.helpmodal.editar.title+" "}
                                <span style={{fontWeight: "700"}}>
                                    {selectedRows[0]["equitab"] != "" && selectedRows[0]["equitab"] ? selectedRows[0]["equitab"]+" - " : ""}
                                    {selectedRows[0]["descripcion"]}
                                </span>
                            </h5>
                        </div>
            return help; 
        }else{
            saveTxtModalDocs(selectedRows.length == 0 
                            ?                             
                                <div className="alert alert-success mt-1" role="alert" style={{width : "fit-content"}}>
                                    <h5 style={{marginBottom : "0px"}}>{confPantalla.runciccfg.padre.helpmodal.crear.title}</h5>
                                </div>  
                            : 
                                <div className="alert alert-primary mt-1" role="alert" style={{width : "fit-content"}}>
                                    <h5 style={{marginBottom : "0px"}}>{confPantalla.runciccfg.padre.helpmodal.editar.title+" "}
                                        <span style={{fontWeight: "700"}}>
                                            {selectedRows[0]["equitab"] != "" && selectedRows[0]["equitab"] ? selectedRows[0]["equitab"]+" - " : ""}
                                            {selectedRows[0]["descripcion"]}
                                        </span>
                                    </h5>
                                </div>                                                    
                            )
        }

    }

    const verDoc = async (step) => {
        const idDoc = registrosSeleccionados.filter((reg) => Object.keys(reg)[0] == "runciccfgTab_"+step)[0]["runciccfgTab_"+step].split("-")[1];    
        
        let ruta = "";
        cookies.get('nivel') == 1 || cookies.get('nivel') == 4 ? ruta = "/documentos" : ruta = "/documentosenc";      
        window.open(ruta+"/"+idDoc, "_blank");
    }
    
    const nextStep = async (step) => {
        saveStepBtnNext(step);        
        saveCantidadEdi([]); // Reiniciamos el array que donde almacenamos los valores cuando editamos la cantidad en el modal
        if(numSteps.length == step ){
            // TODO EN EL ULTIMO PASO NO RECARGAR LA TABLA YA QUE NO EXISTIRIA Y SUPONGO QUE TAMPOCO CREAREMOS DOCUMENTO. SE HARÁN OTRAS ACCIONES. OCULTAR BOTON SIGUIENTE EN EL ULTIMO PASO O HACER ALGO DIFERENTE
            console.log("CLICK BOTON SIGUIENTE ULTIMO PASO");
        }else{                    
            saveLoadingModalNextStep(true);
            saveTablaModalNextStep([]);
            saveInfoModalNextStep({opt: 0, divi: 0, apl: 0});
            getTxtHelp(null, null, null, [])
            const idDoc = registrosSeleccionados.filter((reg) => Object.keys(reg)[0] == "runciccfgTab_"+step)[0]["runciccfgTab_"+step].split("-")[1];
    
            const data = new FormData();      
            data.append("validacion", apiKey());
            data.append("step", step+1);
            data.append("iddoc", idDoc);
            data.append("idciclo", idCiclo);
    
            // Obtenemos la configuración(DIVISIBLE, APILABLE, OPCIONAL)
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.runciccfg.padre.getinfomodalnextstep, data, source.token);       
            if( typeof respuesta.data == "string"){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
            }else{
                saveInfoModalNextStep(respuesta.data[0]);
                
                // Acciones según su configuración(DIVISIBLE, APILABLE, OPCIONAL)
                await actionsByConf(respuesta.data[0].opt, respuesta.data[0].divi, respuesta.data[0].apl, step);                                     
            }
            
            saveLoadingModalNextStep(false);
        }
    }

    const actionsByConf = async (opcional, divisible, apilable, paso) => {  

        // Opcional sacarlo tratarlo aparte. Si es opcional me salto
        switch (divisible + '' + apilable) {
            case '00':  // NO divisible, NO apilable
                            // Consultamos si hay documentos en la tabla siguiente, si hay quiere decir que existe un paso sino creamos el documento del siguiente paso
                            const existeDocSiguiente = $(".runciccfgTab_"+(paso+1)).DataTable().rows().data();
                            if(existeDocSiguiente.length > 0){
                                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.runciccfg.padre.existedoc.title}`, {autoClose: true});
                            }else{
                                // Creamos el documento siguiente
                                okNextStepModal(null, paso, false);
                            }
              break;
            case '01':  // NO divisible, SI apilable
                            // Creamos el documento siguiente independientemente de si hay documento o no
                            okNextStepModal(null, paso, false);                    
              break;
            case '10':  // SI divisible, NO apilable
                            // Nos traemos las lineas, para la tabla de lineas del modal, que NO aparezcan en documentos del siguiente paso
                            const dataTableLineasModal = await getTablaModalLineas(idCiclo, paso, registrosSeleccionados, true);
                            saveShowModalNextStep(true);
                            saveTablaModalNextStep(dataTableLineasModal);
              break;
            case '11':  // SI divisible, SI apilable
                            // Nos traemos TODAS las lineas, para la tabla de lineas del modal
                            const dataTableLineasModalDiviApl = await getTablaModalLineas(idCiclo, paso, registrosSeleccionados, false);
                            saveShowModalNextStep(true);
                            saveTablaModalNextStep(dataTableLineasModalDiviApl);                    
              break;            
          }
        

    }
    
    const okNextStepModal = async (e, pasoNext=null, showModal=null) => {
        let continuar = true;
        let idActualizar = null;
        let selectedLines = null;
        let docActualizar = null;
        let auxSelectedLines = [];
        let filtrarLineas;
        let cerrarModal = true;
        if( ((infoModalNextStep.divi == "1" && infoModalNextStep.apl == "0") || (infoModalNextStep.divi == "1" && infoModalNextStep.apl == "1")) && showModal == null ){
            selectedLines = $('#tableModalLineas').DataTable().rows( { selected: true } ).data();
            if( selectedLines.length == 0 ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.runciccfg.padre.selectlineas.title}`, {autoClose: true})
                continuar = false;
            }else{
                docActualizar = $('#tableModalDocs').DataTable().rows( { selected: true } ).data();
                docActualizar.length > 0 ? idActualizar = docActualizar[0].id : idActualizar = null;
                for(let i=0; i<selectedLines.length; i++){
                    auxSelectedLines.push(selectedLines[i].id)
                }                
            }
            if( infoModalNextStep.divi == "1" && infoModalNextStep.apl == "0" ){
                // SI divisible, NO apilable - Crear o modificar documento con las lineas seleccionadas y eliminarlas del selector de lineas del modal
                filtrarLineas = true;
            }else if(infoModalNextStep.divi == "1" && infoModalNextStep.apl == "1"){
                // SI divisible, SI apilable - Crear o modificar documento con las lineas seleccionadas
                filtrarLineas = false;
            }
            cerrarModal = false;
        }

        if(continuar){
            // Crear el documento correspondiente
            saveLoadingCiclo(true);
    
            !pasoNext ? pasoNext = stepBtnNext : pasoNext = pasoNext;
            
            const idClonar = registrosSeleccionados.filter((reg) => Object.keys(reg)[0] == "runciccfgTab_"+pasoNext)[0]["runciccfgTab_"+pasoNext].split("-")[1];
        
            const data = new FormData();      
            data.append("validacion", apiKey());
            data.append("idciclo", idCiclo);
            data.append("idclonar", idClonar);
            data.append("step", pasoNext);
            data.append("showmodal", showModal);
            data.append("cantidades", JSON.stringify(filterCantidadEdi(cantidadEdi, auxSelectedLines)));
            if(idActualizar){
                data.append("idactualizar", idActualizar);
            }
            if(selectedLines){
                data.append("selectedlines", JSON.stringify(auxSelectedLines));
            }
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.runciccfg.padre.createnextdoc, data, source.token);
            saveShowModal(false);
    
            if( typeof respuesta.data == "string"){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
            }else{
                alertService.success(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.runciccfg.padre.createInitCiclo.success.title}`, {autoClose: true});
                // getDatosTable(idCiclo);
            }
    
            saveLoadingCiclo(false);
    
    
            // Volver a cargar la tabla siguiente(LA QUE CONTIENE EL DOCUMENTO QUE ACABAMOS DE CREAR)
            const dataTableNext = await getDatosTableNextStep(idCiclo, pasoNext, registrosSeleccionados);
            const aux = datosTablas;
            aux[pasoNext] = dataTableNext;
            saveDatosTablas(aux);
    
            if(cerrarModal){
                saveShowModalNextStep(false);
            }else{
                if(filtrarLineas){
                    const dataTableLineasModal = await getTablaModalLineas(idCiclo, stepBtnNext, registrosSeleccionados, true);
                    saveTablaModalNextStep(dataTableLineasModal);
                }else{
                    const dataTableLineasModalDiviApl = await getTablaModalLineas(idCiclo, stepBtnNext, registrosSeleccionados, false);
                    saveTablaModalNextStep(dataTableLineasModalDiviApl);
                }

            }            
            
            saveForceBtnNext(forceBtnNext => forceBtnNext+1)
        }

    }

    const filterCantidadEdi = (cantidadEdi, auxSelectedLines) => {
        let data = [];
        data = cantidadEdi.filter((reg, index) => {
            if(auxSelectedLines.includes(reg.idLin)){
                reg.cant == ''? reg.cant = 0: reg.cant = reg.cant;
                return reg;
            }
        })
        return data;
    }

    const getDatosTableNextStep = async (idCiclo, step, regSeleccionados) => { 

        let datosTabla = [];
        saveLoadingCiclo(true);

        const idClonar = regSeleccionados.filter((reg) => Object.keys(reg)[0] == "runciccfgTab_"+step)[0]["runciccfgTab_"+step].split("-")[1];

        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("idciclo", idCiclo);
        data.append("step", step);
        data.append("idclonar", idClonar);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.runciccfg.padre.getdatostablenextstep, data, source.token);

        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            datosTabla = respuesta.data;
        }
       
        saveLoadingCiclo(false);

        return datosTabla;
    }

    const getTablaModalLineas = async (idCiclo, step, regSeleccionados, filterLines=false) => { 

        let datosTabla = [];
        saveLoadingCiclo(true);

        const idDoc = regSeleccionados.filter((reg) => Object.keys(reg)[0] == "runciccfgTab_"+step)[0]["runciccfgTab_"+step].split("-")[1];

        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("idciclo", idCiclo);
        data.append("step", step);
        data.append("iddoc", idDoc);
        data.append("filterlines", filterLines);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.runciccfg.padre.gettablalineasmodal, data, source.token);

        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            datosTabla = respuesta.data;
        }
       
        saveLoadingCiclo(false);

        return datosTabla;
    }

    const disabledBtnNext = (step) =>{
        let deshabilitar;      

        const guardar = registrosSeleccionados.filter((reg) => Object.keys(reg)[0] == "runciccfgTab_"+step)        
        guardar.length > 0 ? deshabilitar =  false: deshabilitar = true;

        return deshabilitar;
    }



    const handleSelectRegTable = async (e, idTabla) => {
        if( e.target.parentElement.id.includes("#row-")  ){

            const idRow = e.target.parentElement.id;
            const datos = $("."+idTabla).DataTable().rows().data();
            const selectedRows = $('#'+idTabla).DataTable().rows( { selected: true } ).data();
            
            const auxRegistrosSeleccionados = registrosSeleccionados;
            let auxDatosTablas = datosTablas; 

            if(selectedRows.length == 0){ 
                // Al quitar selección vaciamos las tablas siguientes
                auxDatosTablas = clearNextTables(auxDatosTablas, idTabla);
    
                // Para deshabilitar los siguientes botones cuando cambiamos la seleccion de un registro en una tabla
                for (let i = 0; i < auxRegistrosSeleccionados.length; i++) {                    
                    if (Object.keys(auxRegistrosSeleccionados[i])[0] === idTabla) {
                        auxRegistrosSeleccionados.splice(i);
                        break;
                    }
                }
            }else{                 
                if(auxRegistrosSeleccionados.length > 0){
                    const contador = auxRegistrosSeleccionados.length;
                    for (let i = 0; i < contador; i++) {
                        if (Object.keys(auxRegistrosSeleccionados[i])[0] === idTabla) {
                            // Editar                            
                            auxRegistrosSeleccionados[i][idTabla] = idRow;                            
                        }else{
                            const guardar = auxRegistrosSeleccionados.filter(reg =>Object.keys(reg)[i] == idTabla)
                            if(guardar.length == 0){
                                // Añadir los siguientes al primero sino existía
                                const aux = { [idTabla] : idRow };
                                auxRegistrosSeleccionados.push(aux);
                            }
                        }
                    }
                    // Para deshabilitar los siguientes botones cuando cambiamos la seleccion de un registro en una tabla
                    for (let i = 0; i < auxRegistrosSeleccionados.length; i++) {
                        if (Object.keys(auxRegistrosSeleccionados[i])[0] === idTabla) {
                            auxRegistrosSeleccionados.splice(i+1);
                            break;
                        }
                    }
                }else{
                    // Añadir el primero
                    const datosAux = { [idTabla] : idRow };
                    auxRegistrosSeleccionados.push(datosAux)
                }                 
    
                // Al hacer click en una tabla, vaciamos todas las siguientes(la siguiente se vuelve a rellanar en la llamada de abajo)
                auxDatosTablas = clearNextTables(auxDatosTablas, idTabla);                

                // Al hacer click en un paso nos traemos los datos para la tabla del siguiente paso, solo sino estamos haciendo click en la última tabla
                if("runciccfgTab_"+numSteps.length != idTabla){
                    const dataTableNext = await getDatosTableNextStep(idCiclo, idTabla.split("_")[1], auxRegistrosSeleccionados);
                    auxDatosTablas.push(dataTableNext)
                    // saveDatosTablas(datosTablas => [...datosTablas, dataTableNext])                  
                }
            }

            saveRegistrosSeleccionados(auxRegistrosSeleccionados) 
            saveDatosTablas(auxDatosTablas);
            // saveForceBtnNext(forceBtnNext => forceBtnNext+1)
        }

    }

    const clearNextTables = (auxDatosTablas, idTabla) => {
        let data=[];
        let encontrado = false;         
        for(let i=0; i<numSteps.length; i++){
            if (("runciccfgTab_"+(i+1)) == idTabla) {
                encontrado = true;
                data.push(auxDatosTablas[i])
            }else if(encontrado){
                let oTable = $(".runciccfgTab_"+(i+1)).DataTable();
                oTable.clear();
                oTable.draw();
            }else{
                data.push(auxDatosTablas[i])
            }
        }
        return data;
    }

    
    return(
        <div className="App wrapper">    
        <Alert fondo={true} />
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                            <Section titulo={ confPantalla.runciccfg.section.titulo } icono={ confPantalla.runciccfg.section.icono } col={ confPantalla.runciccfg.section.col } idPrin={idCiclo} wiki={ confPantalla.runciccfg.section.wiki } >
                                <MiSelect  
                                    estadoForm=  { confPantalla.runciccfg.padre.estadoform }
                                    val=         { idCiclo  }   
                                    loading=     { loadingCiclo ? true : false }
                                    id=          { confPantalla.runciccfg.padre.idciccfg.id }    
                                    name=        { confPantalla.runciccfg.padre.idciccfg.name }   
                                    label=       { confPantalla.runciccfg.padre.idciccfg.label }    
                                    placeholder= { confPantalla.runciccfg.padre.idciccfg.placeholder }
                                    col=         { confPantalla.runciccfg.padre.idciccfg.col } 
                                    isClearable= { confPantalla.runciccfg.padre.idciccfg.isclearable }
                                    isSearchable={ confPantalla.runciccfg.padre.idciccfg.issearchable }
                                    dataType=    { confPantalla.runciccfg.padre.idciccfg.datatype }
                                    data=        { confPantalla.runciccfg.padre.idciccfg.data }
                                    required=    { confPantalla.runciccfg.padre.idciccfg.required } 
                                    disabled=  { confPantalla.runciccfg.padre.idciccfg.disabled }
                                    readOnly =   { confPantalla.runciccfg.padre.idciccfg.readonly }
                                    disablethisid={confPantalla.runciccfg.padre.idciccfg.disablethisid}
                                    disableids={confPantalla.runciccfg.padre.idciccfg.disableids}
                                    formulario= { {id:0} }   
                                    change= { handleSelectCiclo }
                                />
                                {  loadingCiclo ? 
                                        <SpinnerFloat />
                                    :
                                     null
                                }
                                {
                                    idCiclo != ""
                                    ?
                                        <Button 
                                            clases={ "btn-block mb-3" }
                                            color={ "success" }  
                                            type="button" 
                                            icono={ "fal fa-plus" }   
                                            id={"btnAdd"}     
                                            texto={ " "+confPantalla.runciccfg.padre.btnadd.title }   
                                            accion={ () => showModalFn() } 
                                            disabled={ loadingCiclo ? true : false }
                                        /> 
                                    :
                                        null
                                }
                                {
                                    idCiclo != ""
                                    ?
                                    <Tabs defaultActiveKey="tab_1" id="tab1">
                                        {
                                            numSteps.map((linea, index) => {

                                                let lengthTabla = 0;
                                                
                                                const btnNext = <Fragment>
                                                                    <Button
                                                                        index={"btn-"+(index+1)}
                                                                        clases={ " mb-3" }
                                                                        color={ "primary" }  
                                                                        type="button" 
                                                                        icono={ "fal fa-eye" }   
                                                                        id={"btnnext-"+(index+1)}     
                                                                        texto={ " "+confPantalla.runciccfg.padre.btnver.title }   
                                                                        accion={ () => verDoc(index+1) }
                                                                        disabled={ !loadingCiclo || forceBtnNext ? disabledBtnNext(index+1) : true }
                                                                    />
                                                                    <Button
                                                                        index={"btn-"+(index+1)}
                                                                        clases={ " mb-3" }
                                                                        color={ "success" }  
                                                                        type="button" 
                                                                        icono={ "fal fa-arrow-to-right" }   
                                                                        id={"btnnext-"+(index+1)}     
                                                                        texto={ numSteps.length == index+1 ? " "+confPantalla.runciccfg.padre.btnfin.title : " "+confPantalla.runciccfg.padre.btnnext.title }   
                                                                        accion={ () => nextStep(index+1) }
                                                                        disabled={ !loadingCiclo || forceBtnNext ? disabledBtnNext(index+1) : true }
                                                                    />
                                                                </Fragment>
                                                
                                                let tabla = null;
                                                let lineaSelect;                                                
                                                lineaSelect = registrosSeleccionados[index] && registrosSeleccionados[index]["runciccfgTab_"+(index+1)]
                                                                                ? lineaSelect = registrosSeleccionados[index]["runciccfgTab_"+(index+1)].split("-")[1] 
                                                                                : lineaSelect = null;
                                                tabla = <div onClick={(e, idTabla)=> handleSelectRegTable(e, "runciccfgTab_"+(index+1))}>
                                                            <MiDataTableRunciccfg 
                                                                    estadoForm= { "" }
                                                                    formulario={ {id:0} }
                                                                    id={"runciccfgTab_"+(index+1)}
                                                                    columnas={confPantalla.runciccfg.padre.datatable.columns}
                                                                    data={datosTablas[index]}
                                                                    buttons={confPantalla.runciccfg.padre.datatable.buttons}
                                                                    ordering={confPantalla.runciccfg.padre.datatable.ordering}
                                                                    order={confPantalla.runciccfg.padre.datatable.order}
                                                                    searching={confPantalla.runciccfg.padre.datatable.searching}
                                                                    paging={confPantalla.runciccfg.padre.datatable.paging}
                                                                    select={confPantalla.runciccfg.padre.datatable.select}
                                                                    pagelength={confPantalla.runciccfg.padre.datatable.pagelength}
                                                                    // saveLoadingTable={ saveLoadingTable }
                                                                    // loadingTable={}
                                                                    columnactions={confPantalla.runciccfg.padre.datatable.columnactions.title}
                                                                    linkColumns={confPantalla.runciccfg.padre.datatable.linkColumns}
                                                                    traduction={confPantalla.config.trad.components.datatable}
                                                                    // functionAfterSelect={handleSelectRegTable}
                                                                    registrosSeleccionados={registrosSeleccionados}
                                                                    datosTablas={datosTablas}
                                                                    selectId={{id:lineaSelect}}
                                                            />
                                                        </div>
                                                if(datosTablas[index]){
                                                    lengthTabla = datosTablas[index].length
                                                }else{
                                                    lengthTabla = 0;
                                                }                                                
                                                return(
                                                    <Tab index={(index+1)} eventKey={"tab_"+(index+1)} title={ titleTabs[index] ? (index+1)+"-"+titleTabs[index].tipo+" ("+lengthTabla+")" : (index+1) }>
                                                        {btnNext}
                                                        {tabla}                                                                                               
                                                    </Tab>

                                                )
                                            })
                                        }
                                    </Tabs>
                                    :
                                        null
                                }
                                                                                                            
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer /> 
        {
            showModal 
            ?
                <Modal
                    show={showModal}
                    onHide={ () => saveShowModal(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}                    
                    dialogClassName="modal-90w" 
                    >
                    <Modal.Header closeButton>                        
                    </Modal.Header>                            
                    <Modal.Body>
                        <MiSelect  
                            estadoForm=  { confPantalla.runciccfg.padre.estadoform }
                            val=         { idNuevoCiclo  }   
                            loading=     { false }
                            id=          { confPantalla.runciccfg.padre.documentos.id }    
                            name=        { confPantalla.runciccfg.padre.documentos.name }   
                            label=       { confPantalla.runciccfg.padre.documentos.label }    
                            placeholder= { confPantalla.runciccfg.padre.documentos.placeholder }
                            col=         { confPantalla.runciccfg.padre.documentos.col } 
                            isClearable= { confPantalla.runciccfg.padre.documentos.isclearable }
                            isSearchable={ confPantalla.runciccfg.padre.documentos.issearchable }
                            dataType=    { confPantalla.runciccfg.padre.documentos.datatype }
                            data=        { confPantalla.runciccfg.padre.documentos.data }
                            required=    { confPantalla.runciccfg.padre.documentos.required } 
                            disabled=  { confPantalla.runciccfg.padre.documentos.disabled }
                            readOnly =   { confPantalla.runciccfg.padre.documentos.readonly }
                            disablethisid={confPantalla.runciccfg.padre.documentos.disablethisid}
                            disableids={confPantalla.runciccfg.padre.documentos.disableids}
                            formulario= { {id:0} }   
                            change= { handleSelectNuevoCiclo }
                        />
                        <p className="font-italic text-center">{confPantalla.runciccfg.padre.help.title}</p>
                        <button type="button" className="btn btn-flat btn-success btn-block " onClick={ (e) => nuevoCiclo(e) }>
                            {confPantalla.runciccfg.padre.btncrear.title}
                        </button>
                        {  loadingNuevoCiclo ? 
                                <div className='mt-4' style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                    <Spinner animation="border" />
                                </div>
                            :
                            null
                        }
                    </Modal.Body>                            
                </Modal>
            :
                    null
        }
        {
            showModalNextStep 
            ?
                <Modal
                    show={showModalNextStep}
                    onHide={ () => saveShowModalNextStep(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}                    
                    dialogClassName="modal-90w" 
                    >
                    <Modal.Header closeButton>
                        {getHeaderModalNextStep()}
                    </Modal.Header>                          
                    <Modal.Body>
                        {  loadingModalNextStep ? 
                                <div className='mt-4' style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                    <Spinner animation="border" />
                                </div>
                            :
                            null
                        }
                        {/* <div className="alert alert-secondary alertHelp" role="alert" style={{width : "fit-content"}}>
                            <h5 style={{marginBottom : "0px"}}>
                                {confPantalla.runciccfg.padre.helpmodal.paso.title} {stepBtnNext+1}/{numSteps.length}
                            </h5>
                        </div> */}
                        <div className="row" >
                            <div className="col-12">
                                <div className="row justify-content-md-center"> 
                                    <div className="col-md-12 col-lg-2">
                                        <div className="alert alert-secondary alertHelp" role="alert">
                                            <h5 style={{marginBottom : "0px"}}>
                                                {confPantalla.runciccfg.padre.helpmodal.paso.title} {stepBtnNext+1}/{numSteps.length}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-2">
                                        <div className={ infoModalNextStep.opt == 0 ? "alert alert-danger alertHelp text-center"  : "alert alert-success alertHelp text-center" } role="alert">
                                            <h5 style={{marginBottom : "0px"}}>
                                                {confPantalla.runciccfg.padre.helpmodal.opcional.title}
                                                {
                                                    infoModalNextStep.opt == 0 
                                                    ? <i className="ml-1 fal fa-square" style={{fontSize : "1.2em"}}></i>
                                                    : <i className="ml-1 fal fa-check-square" style={{fontSize : "1.2em"}}></i>
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-2">
                                        <div className={infoModalNextStep.divi == 0 ? "alert alert-danger alertHelp text-center"  : "alert alert-success alertHelp text-center"} role="alert">
                                            <h5 style={{marginBottom : "0px"}}>
                                                {confPantalla.runciccfg.padre.helpmodal.divisible.title}
                                                {
                                                    infoModalNextStep.divi == 0 
                                                    ? <i className="ml-1 fal fa-square" style={{fontSize : "1.2em"}}></i>
                                                    : <i className="ml-1 fal fa-check-square" style={{fontSize : "1.2em"}}></i>
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-2">
                                        <div className={infoModalNextStep.apl == 0 ? "alert alert-danger alertHelp text-center"  : "alert alert-success alertHelp text-center"} role="alert">
                                            <h5 style={{marginBottom : "0px"}}>
                                                {confPantalla.runciccfg.padre.helpmodal.apilable.title}
                                                {
                                                    infoModalNextStep.apl == 0 
                                                    ? <i className="ml-1 fal fa-square" style={{fontSize : "1.2em"}}></i>
                                                    : <i className="ml-1 fal fa-check-square" style={{fontSize : "1.2em"}}></i>
                                                }
                                            </h5>
                                        </div>
                                    </div>                                                                                         
                                </div>
                            </div>
                        </div>
                        <MiDataTableRunciccfg
                            formulario={{id:0}}
                            id={"tableModalDocs"}
                            columnas={confPantalla.runciccfg.padre.datatablemodaldocs.columns}
                            data={datosTablas[stepBtnNext]}
                            buttons={confPantalla.runciccfg.padre.datatablemodaldocs.buttons}
                            ordering={confPantalla.runciccfg.padre.datatablemodaldocs.ordering}
                            order={confPantalla.runciccfg.padre.datatablemodaldocs.order}
                            searching={confPantalla.runciccfg.padre.datatablemodaldocs.searching}
                            paging={confPantalla.runciccfg.padre.datatablemodaldocs.paging}
                            select={confPantalla.runciccfg.padre.datatablemodaldocs.select}
                            pagelength={confPantalla.runciccfg.padre.datatablemodaldocs.pagelength}                           
                            columnactions={confPantalla.runciccfg.padre.datatablemodaldocs.title}
                            traduction={confPantalla.config.trad.components.datatable}
                            functionAfterSelect={getTxtHelp}
                        />
                        <div className="row" >
                            <div className="col-12">
                                <div className="row justify-content-md-center">
                                    {getTxtHelp(null, null, null, null, true)}
                                </div>
                            </div>
                        </div>
                        <MiDataTableRunciccfg
                            formulario={{id:0}}
                            id={"tableModalLineas"}
                            columnas={confPantalla.runciccfg.padre.datatablemodallineas.columns}
                            data={tablaModalNextStep}
                            buttons={confPantalla.runciccfg.padre.datatablemodallineas.buttons}
                            ordering={confPantalla.runciccfg.padre.datatablemodallineas.ordering}
                            order={confPantalla.runciccfg.padre.datatablemodallineas.order}
                            searching={confPantalla.runciccfg.padre.datatablemodallineas.searching}
                            paging={confPantalla.runciccfg.padre.datatablemodallineas.paging}
                            select={confPantalla.runciccfg.padre.datatablemodallineas.select}
                            pagelength={confPantalla.runciccfg.padre.datatablemodallineas.pagelength}                           
                            columnactions={confPantalla.runciccfg.padre.datatablemodallineas.title}
                            traduction={confPantalla.config.trad.components.datatable}
                            cantidad={cantidadEdi}
                            saveCantidad={saveCantidadEdi}
                        />
                    {  loadingCiclo ? 
                            <div className='mt-4' style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                <Spinner animation="border" />
                            </div>
                        :
                        null
                    }
                    <button type="button" className="btn btn-flat btn-success mt-3 float-right " onClick={ (e) => okNextStepModal(e) } disabled={loadingCiclo ? true : false}>
                            {" "+confPantalla.runciccfg.padre.btnnext.title}
                    </button>
                    <button type="button" className="mr-2 btn btn-flat btn-danger mt-3 float-right " onClick={ () => saveShowModalNextStep(false) } disabled={loadingCiclo ? true : false}>
                            {" "+confPantalla.runciccfg.padre.btncancelar.title}
                    </button>
                    </Modal.Body>                            
                </Modal>
            :
                    null
        }       
        </div>

    );

}
export default RunCicCfg